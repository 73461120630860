<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Dokumen Pasien</b></div>
      <b-button
        squared
        variant="success"
        v-b-modal.modal-form
        v-if="manipulateBtn == true"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="row" v-if="page == 'list'">
          <div class="col-md-12">
            <Card>
              <template v-slot:body>
                <!-- Filter -->
                <div class="row justify-content-end mt-n3">
                  <div class="col-md-4">
                    <h4 v-if="showAllDocument == false" class="mt-2">
                      Dokumen Hari Ini
                    </h4>
                    <h4 v-if="showAllDocument == true" class="mt-2">
                      Semua Dokumen
                    </h4>
                  </div>
                  <div class="col-md-4">
                    <b-input-group class="d-flex justify-content-end">
                      <b-input-group-prepend>
                        <b-button
                          squared
                          class="btn float-right btn-outline-success"
                          :class="{ active: btntype.all }"
                          @click="handleAllDocument"
                          >Semua Dokumen</b-button
                        >
                      </b-input-group-prepend>
                      <b-button
                        squared
                        class="btn float-right btn-outline-success"
                        :class="{ active: btntype.today }"
                        @click="handleList"
                        >Dokumen Hari Ini</b-button
                      >
                    </b-input-group>
                  </div>
                  <div class="col-md-4">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text squared>
                          <b-icon-search></b-icon-search>
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        type="text"
                        v-model="find"
                        placeholder="Cari Pasien..."
                        @change="getDocumentByName"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>

                <div
                  class="row align-content-center"
                  v-if="tableSide == false && dataSign == true"
                >
                  <div class="col-12 text-center">
                    <h6 class="text-danger">Dokumen Tidak Ditemukan</h6>
                  </div>
                  <div class="col-12 text-center">
                    <small
                      >Silahkan Tambah Dokumen Terlebih Dahulu,<a
                        @click="handleList"
                        class="text-primary"
                        >Kembali Ke Tabel</a
                      ></small
                    >
                  </div>
                </div>

                <div v-if="tableSide == true">
                  <!-- <div class="row" v-if="tableHide == false">
                    <div class="col-md-12">
                      <Table purpose="master" :listData="listData"></Table>
                    </div>
                    <hr />
                  </div> -->

                  <!-- Sign for empty daily document -->
                  <div class="row" v-if="tableHide == true">
                    <div class="col-12 text-center">
                      <h6 class="text-danger">Tidak Ada Dokumen Baru</h6>
                    </div>
                    <div class="col-12 text-center">
                      <small>Silahkan Cari Untuk Melihat Dokumen Pasien</small>
                    </div>
                  </div>
                </div>

                <div class="row mt-0">
                  <div
                    class="col-md-3"
                    v-for="document in documents"
                    :key="document.id"
                  >
                    <div
                      class="card border-0 card-document"
                      style="cursor: default"
                      @dblclick="handleDetail(document.patient_id)"
                    >
                      <div
                        class="card-body py-2 px-1 d-flex justify-content-start align-items-center"
                      >
                        <div class="row">
                          <div class="col-md-12 pb-1 text-center">
                            <i
                              class="fas fa-fw fa-6x fa-folder color-brown"
                            ></i>
                          </div>
                          <div class="col-md-12 pt-1 text-center">
                            <span class="font-weight-light">
                              {{
                                document.patient_name.length > 10
                                  ? document.patient_name
                                  : document.patient_name
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>

        <div class="row" v-if="page == 'detail'">
          <div class="col-md-12" v-if="dataLoaded == true">
            <Card>
              <template v-slot:body>
                <div v-if="detailEmpty == true">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <span>Dokumen Tidak Tersedia</span>
                    </div>
                  </div>
                </div>
                <div v-if="detailEmpty == false">
                  <div class="row align-items-center">
                    <div class="col-md-12 d-inline pb-1">
                      <b-alert show variant="primary mb-0">
                        <span
                          class="text-teal"
                          style="cursor: pointer"
                          @click="handleList"
                          >Dokumen</span
                        >
                        <!-- <i class="fas fa-fw fa-arrow-right text-light"></i> -->
                        <i class="fas fa-sm fa-circle text-light px-2"></i>
                        <span class="text-teal">{{
                          list != [] ? list[0].patient_name : ""
                        }}</span>
                        <!-- <i class="fas fa-fw fa-arrow-right text-light"></i> -->
                        <i class="fas fa-sm fa-circle text-light px-2"></i>
                        <span class="font-weight-bold"> Detail</span>
                      </b-alert>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div
                      class="col-md-3 my-2 d-flex justify-content-center"
                      v-for="(attachment, index) in attachments"
                      :key="attachment.id"
                    >
                      <!-- <a download="file" :href="attachments[index]" > -->
                      <div
                        class="card border-0 card-document"
                        @dblclick="newWindow(attachments[index])"
                      >
                        <div
                          class="card-body p-1 d-flex justify-content-start align-items-center"
                          style="cursor: default"
                        >
                          <div class="row py-2 px-0">
                            <div
                              class="col-md-12 d-flex justify-content-end p-0 m-0"
                            >
                              <b-dropdown
                                size="sm"
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                class="p-0 m-0"
                                dropright
                              >
                                <template #button-content>
                                  <i
                                    class="fas fa-lg fa-ellipsis-v mr-2 text-dark"
                                  ></i>
                                </template>
                                <b-dropdown-item
                                  @click="detailDocument(dataId[index])"
                                  ><i
                                    class="fas fa-md fa-eye text-success mt-1 mr-2"
                                  ></i>
                                  Detail</b-dropdown-item
                                >
                                <b-dropdown-item
                                  @click="editDocument(dataId[index])"
                                  ><i
                                    class="fas fa-md fa-edit text-success mt-1 mr-2"
                                  ></i>
                                  Edit</b-dropdown-item
                                >
                                <b-dropdown-item
                                  @click="deleteDocument(dataId[index])"
                                  ><i
                                    class="fas fa-md fa-trash-alt text-danger mt-1 mr-2"
                                  ></i>
                                  Hapus</b-dropdown-item
                                >
                              </b-dropdown>
                            </div>
                            <div
                              class="col-md-12 d-flex justify-content-center px-0 pb-1"
                            >
                              <i
                                class="fas fa-fw fa-7x fa-file"
                                v-if="
                                  fileExt[index] != 'png' &&
                                  fileExt[index] != 'jpg' &&
                                  fileExt[index] != 'jpeg'
                                "
                              ></i>

                              <div
                                class="d-flex justify-content-center align-items-center"
                                v-if="
                                  fileExt[index] == 'png' ||
                                  fileExt[index] == 'jpg' ||
                                  fileExt[index] == 'jpeg'
                                "
                              >
                                <div
                                  style="
                                    height: 170px;
                                    width: 170px;
                                    position: relative;
                                  "
                                >
                                  <img
                                    :src="attachments[index]"
                                    class="image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-12 d-flex justify-content-center pt-1"
                            >
                              <span
                                class="font-weight-light text-center text-dark"
                              >
                                {{
                                  fileNameDetail[index].length > 10
                                    ? fileNameDetail[index]
                                    : fileNameDetail[index]
                                }}
                                <!-- {{fileNameDetail[index]}} -->
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- </a> -->
                    </div>
                  </div>
                </div>

                <b-button
                  size="sm"
                  class="btn-primary mt-3"
                  @click="handleList"
                  v-if="documentsLoad == false"
                >
                  Kembali
                </b-button>
                <b-button
                  size="sm"
                  class="btn-primary mt-3"
                  @click="handleAllDocument"
                  v-if="documentsLoad == true"
                >
                  Kembali
                </b-button>
              </template>
            </Card>
          </div>
        </div>

        <!-- add -->
        <Form />
        <!-- edit -->
        <FormEdit :form="form"></FormEdit>
        <!-- detail -->
        <ModalDetail :data="detail"></ModalDetail>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/component/patients/DocumentHistory.vue";
import module from "@/core/modules/CrudModule.js";
import Card from "@/view/content/Card.vue";
import Form from "@/component/patients/DocumentForm.vue";
import FormEdit from "@/component/patients/DocumentEdit.vue";
import ModalDetail from "@/component/patients/DocumentDetail.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export default {
  data() {
    return {
      // data
      tableSide: true, //for showing table
      dataSign: false, //find or not data notification
      dataLoaded: false, //data load status
      documents: [],
      find: "",
      date: "",
      page: "list",
      list: "",
      listData: [],
      documentsLoad: false,
      tableHide: false, //for hide only table nnot side
      allDayDetail: "false",
      attachments: [],
      fileName: [],
      fileNameDetail: [],
      fileExt: [],
      patientName: [],
      attachment: [],
      showAllDocument: false, //show all document status
      dataId: [],
      detailEmpty: false,
      btntype: {
        all: false,
        today: true,
      },

      // latest id
      latestId: "",
      editId: "",
      // access management
      manipulateBtn: false,
      fields: [
        {
          key: "no",
          label: "No",
          thClass: "text-center",
          tdClass: "text-center ",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Nama Pasien",
          thClass: "text-center",
          tdClass: "text-center ",
          sortable: true,
        },
        {
          key: "document_name",
          label: "Nama Dokumen",
          thClass: "text-center",
          tdClass: "text-center ",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Form
      form: {
        title: "",
        description: "",
      },
      detail: {},
    };
  },

  components: {
    Card,
    Form,
    FormEdit,
    ModalDetail,
    Table,
  },

  methods: {
    newWindow(url) {
      window.open(url);
    },
    handleDate() {
      var currentDate = new Date();
      var day = currentDate.getDate();
      var month = currentDate.getMonth() + 1;
      var year = currentDate.getFullYear();
      let date = day + "-" + month + "-" + year;
      return date;
    },
    handleAllDocument() {
      this.dataLoaded = false;
      this.tableHide = true;
      this.tableSide = false;
      this.dataSign = false;
      this.showAllDocument = true;
      this.documentsLoad = true;
      this.find = "";
      this.page = "list";

      // btn type
      this.btntype.all = true;
      this.btntype.today = false;
      this.getAllDocument();
    },
    handleDetail(id) {
      this.dataLoaded = false;
      this.page = "detail";
      this.latestId = id;

      this.getDocument(id);
    },
    // handleToday(){
    //   this.dataLoaded = false
    //   this.tableHide = true
    //   this.tableSide = false
    //   this.dataSign = false
    //   this.showAllDocument =
    //   this.find = ""
    //   this.page = "list";
    //   this.getAllDocument()
    // },
    handleList() {
      this.dataLoaded = false;
      this.tableHide = false;
      this.tableSide = true;
      this.dataSign = false;
      this.showAllDocument = false;
      this.documentsLoad = false;
      this.find = "";
      this.page = "list";
      // btn type
      this.btntype.today = true;
      this.btntype.all = false;
      console.log(this.btntype.today, "this.btntype.today");
      this.getDocuments();
    },

    async getDocuments() {
      this.fileName = [];
      this.patientName = [];
      let response = await module.get("documents");
      this.documents = response;
      this.dataLoaded = true;

      // table list daily

      this.listData = await module.get("documents-list");

      let a;
      let b;
      let arr = [];
      let arrFile = [];

      this.listData.forEach((el) => {
        let indexArr = arr.findIndex((x) => x.patient_id == el.patient_id);

        if (indexArr == -1) {
          el.fileArr = [];
          el.file_name = [];
          let name = el.files.split("$");
          el.file_name.push(name[1].split("_").join(" "));
          el.fileArr.push(el.files);

          arr.push(el);
        } else {
          let name = el.files.split("$");
          arr[indexArr].file_name.push(name[1].split("_").join(" "));
          arr[indexArr].fileArr.push(el.files);
        }
      });

      this.listData = arr;

      // for (a = 0; a <= this.listData.length - 1; a++) {

      //   if(indexArr != -1){

      //     arrFile.push(this.listData[a].files)

      //   }else{
      //     arr.push(this.listData[a])

      //   }

      //   // // explode files
      //   // this.listData[a].files = this.listData[a].files.split(",");
      //   // this.listData[a]["files_name"] = [];
      //   // for (b = 0; b <= this.listData[a].files.length - 1; b++) {
      //   //   // get name files
      //   //   let dataName = this.listData[a].files[b].split("$");
      //   //   this.listData[a]["files_name"].push(dataName[1].split("_").join(" "));
      //   // }
      // }
    },

    async getDocument(id) {
      this.list = [];
      this.attachments = [];
      this.dataId = [];
      this.fileNameDetail = [];
      this.fileExt = [];
      let listData = await module.get(
        "documents/" + id + "/" + this.allDayDetail
      );

      if (listData) {
        this.list = listData;

        console.log(listData);
        this.list.forEach((el) => {
          this.attachments.push(el.files);
          this.fileNameDetail.push(el.title_file);
          this.fileExt.push(el.ext);
          this.dataId.push(el.id);
        });

        this.dataLoaded = true;

        if (listData.length == 0) {
          this.tableHide = true;
        } else {
          this.tableHide = false;
        }
      } else {
        this.detailEmpty = true;
      }
      console.log(this.fileNameDetail, "this.fileNameDetail");
    },

    async getDocumentByName() {
      if (this.find != "") {
        let response = await module.get("documents-name/" + this.find);
        if (response.length == 0) {
          this.tableSide = false;
          this.tableHide = true;
          this.dataSign = true;
        } else {
          this.tableHide = true;
          this.tableSide = false;
          this.dataSign = false;
        }
        this.allDayDetail = "true";
        this.documents = response;
      } else {
        this.dataSign = false;
        this.tableSide = true;
        this.tableHide = false;
        this.allDayDetail = "false";
        this.getDocuments();
      }
    },

    async getAllDocument() {
      let response = await module.get("documents-all");
      if (response.length == 0) {
        this.tableSide = false;
        this.tableHide = true;
        this.dataSign = true;
      } else {
        this.tableHide = true;
        this.tableSide = false;
        this.dataSign = false;
      }
      this.allDayDetail = "true";
      this.documents = response;
      this.dataLoaded = true;
    },
    // async getDocumentOnFolder() {
    //   if(this.find != ""){
    //     let response = await module.get("documents-name/" + this.find);
    //     this.documents = response;

    //   }else{
    //     this.getDocuments()
    //   }
    // },

    async deleteDocument(id) {
      let result = await module.delete("documents/" + id);

      if (result) {
        this.getDocument(this.latestId);
      }
    },

    async editDocument(id) {
      this.editId = id;

      let response = await module.get("documents/" + this.editId);
      console.log(response, "res");

      this.form.id = response.id;
      this.form.title = response.title;
      this.form.description = response.description;
      this.form.patient_id = response.patient_id;
      this.form.format = response.format;
      this.form.file = response.files;
      this.form["_method"] = "put";

      this.$bvModal.show("modal-form-edit");
    },

    async detailDocument(id) {

      let response = await module.get("documents/" + id);
      this.detail = response

      this.$bvModal.show("modal-detail");
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3008") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien" },
      { title: "Dokumen" },
    ]);
    this.getDocuments();
    this.setActiveMenu();
  },
};
</script>

<style scoped>
.card-document:hover {
  background-color: #e5f3ff !important;
}

/* .card-document {
  border: 2px solid #e3e3e3 !important;
} */
.color-brown {
  color: #ffe383 !important;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
