<template>
  <div>
    <div>
      <b-modal
        id="modal-detail"
        size="xl"
        title="Detail Dokumen"
        @hidden="modalOnHide"
      >
        <div class="row">
          <div
            class="col-md-8 d-flex justify-content-center"
            v-if="data.format == 'image'"
          >
            <div
              style="height: 500px; width: 500px; position: relative"
              @dblclick="openWindow(data.files)"
            >
              <img :src="data.files" class="image" />
            </div>
          </div>

          <div
            class="col-md-4 d-flex justify-content-center"
            v-if="data.format != 'image'"
            @dblclick="openWindow(data.files)"
          >
            <i class="fas fa-fw fa-10x fa-file"></i>
          </div>

          <div class="col">
            <h3>{{ data.title }}</h3>
            <p>{{ data.description }}</p>
            <small v-if="data.format != 'image'" style="color: red;" class="mt-3">Catatan: Klik 2x pada icon file untuk membuka dokumen</small>
          </div>
        </div>
        <template #modal-footer>
          <b-button size="md" variant="primary" @click="modalOnHide">
            Tutup
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/component/general/ModalPatient.vue";
import module from "@/core/modules/CrudModule.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import validation from "@/core/modules/ValidationModule.js";

export default {
  components: {
    Treeselect,
    Modal,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      // file type
      typeFile: "",
     
      itemsTable: {},
      // Options
      patients: [],
      formats: [
        { text: "Pdf", value: "pdf" },
        { text: "Docx", value: "docx" },
        { text: "Xls", value: "xls" },
        { text: "Jpg", value: "jpg" },
        { text: "Jpeg", value: "jpeg" },
        { text: "Png", value: "png" },
        { text: "Lainnya", value: "other" },
      ],
      // data
      formData: new FormData(),
      // display
      display: {
        patient_name: this.$route.query.patient_name ?? "",
      },
    };
  },

  methods: {

    // async formOnSubmit() {
    //   // Make Request

    //   console.log(this.form);

    //   let response = await module.submit(
    //     this.form,
    //     "documents/" + this.form.id
    //   );
    //   // Check Response
    //   if (response.state == "error") {
    //     // Validation Error
    //     this.error = validation.setValidationError(
    //       this.error,
    //       response.error.errors
    //     );
    //     console.log(this.error);
    //   } else {
    //     // Success
    //     Swal.fire(response.success.title, response.success.message, "success");
    //     this.$bvModal.hide("modal-form-edit");
    //   }

    //   this.$parent.getDocument(this.form.patient_id);
    // },

    // modalOnSubmit(evt) {
    //   evt.preventDefault();
    //   this.formOnSubmit();
    // },

    openWindow(url) {
      window.open(url);
    },

    modalOnHide(evt) {
      // this.error = validation.clearValidationError(this.error);
      // this.form = validation.clearForm(this.form);
      this.$bvModal.hide("modal-detail");
    },
  },

  mounted() {
    // this.getPatientsOption();
    // this.getDocumentData();
  },
};
</script>

<style scoped>
.btn-outline-purple {
  border: 1.28px solid !important;
  border-color: #305b8f;
  color: #305b8f !important;
  transition: all ease-in-out 0.3s;
}

.card-rounded {
  border: 1.28px solid !important;
  border-radius: 15px;
  border-color: #305b8f;
  color: #305b8f !important;
  transition: all ease-in-out 0.3s;
}

.btn-outline-purple:hover {
  border-color: #305b8f !important;
  background: #305b8f;
  color: white !important;
}

.btn-rounded {
  border-radius: 15px;
}
</style>
