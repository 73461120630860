<template>
  <div>
    <div>
      <b-modal
        id="modal-form-edit"
        size="xl"
        title="Edit Dokumen"
        @hidden="modalOnHide"
      >
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-md-4 d-flex justify-content-center">
              <div
                style="height: 300px; width: 300px; position: relative"
                v-if="form.format == 'image'"
                @dblclick="openWindow(form.file)"
              >
                <img :src="form.file" class="image" />
              </div>
              <i
                class="fas fa-fw fa-10x fa-file"
                v-if="form.format != 'image'"
              ></i>
            </div>
            <div class="col-md-8">
              <b-form-group
                id="input-group-title"
                label="Judul:"
                label-for="input-title"
              >
                <b-form-input
                  id="input-title"
                  v-model="form.title"
                  placeholder="Judul Dokumen"
                ></b-form-input>
                <small class="text-danger">{{ error.title }}</small>
              </b-form-group>

              <!-- description -->
              <b-form-group id="input-group-case">
                <label for="input-email"
                  >Deskripsi: <em class="text-muted">opsional</em></label
                >
                <b-form-textarea
                  id="textarea"
                  v-model="form.description"
                  placeholder=""
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <small class="text-danger">{{ error.description }}</small>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template #modal-footer>
          <b-button size="md" variant="primary" @click="modalOnSubmit">
            Simpan
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/component/general/ModalPatient.vue";
import module from "@/core/modules/CrudModule.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import validation from "@/core/modules/ValidationModule.js";

export default {
  components: {
    Treeselect,
    Modal,
  },

  props: {
    form: Object,
  },

  data() {
    return {
      // file type
      typeFile: "",
      // Error
      error: {
        patient_id: "",
        format: "",
        description: "",
        title: "",
        files: "",
        check_date: "",
      },
      itemsTable: {},
      // Options
      patients: [],
      formats: [
        { text: "Pdf", value: "pdf" },
        { text: "Docx", value: "docx" },
        { text: "Xls", value: "xls" },
        { text: "Jpg", value: "jpg" },
        { text: "Jpeg", value: "jpeg" },
        { text: "Png", value: "png" },
        { text: "Lainnya", value: "other" },
      ],
      // data
      formData: new FormData(),
      // display
      display: {
        patient_name: this.$route.query.patient_name ?? "",
      },
    };
  },

  methods: {
    // setPatient(value) {
    //   this.form.patient_id = value.id;
    //   this.display.patient_name = value.name;
    //   this.$bvModal.hide("modal-patient");
    // },

    // async getDocumentData() {

    // },

    // upload(event) {
    //   this.itemsTable = event.target.files;
    //   // Object.keys(event.target.files).forEach((el) => {});

    //   if (event.target.files.length > 0) {
    //     this.formData = new FormData();
    //   }

    //   for (let a = 0; a <= event.target.files.length; a++) {
    //     this.formData.append("files[]", event.target.files[a]);
    //   }
    // },

    async formOnSubmit() {
      // Make Request

      console.log(this.form);

      let response = await module.submit(
        this.form,
        "documents/" + this.form.id
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
        console.log(this.error);
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$bvModal.hide("modal-form-edit");
      }

      this.$parent.getDocument(this.form.patient_id);
    },

    modalOnSubmit(evt) {
      evt.preventDefault();
      this.formOnSubmit();
    },
   
   openWindow(url) {
      window.open(url)
    },

    modalOnHide(evt) {
      this.error = validation.clearValidationError(this.error);
      this.form = validation.clearForm(this.form);
      this.$bvModal.hide("modal-form-edit");
    },
  },

  mounted() {
    // this.getPatientsOption();
    // this.getDocumentData();
  },
};
</script>

<style scoped>
.btn-outline-purple {
  border: 1.28px solid !important;
  border-color: #305b8f;
  color: #305b8f !important;
  transition: all ease-in-out 0.3s;
}

.card-rounded {
  border: 1.28px solid !important;
  border-radius: 15px;
  border-color: #305b8f;
  color: #305b8f !important;
  transition: all ease-in-out 0.3s;
}

.btn-outline-purple:hover {
  border-color: #305b8f !important;
  background: #305b8f;
  color: white !important;
}

.btn-rounded {
  border-radius: 15px;
}
</style>
