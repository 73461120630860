<template>
  <v-app>
    <div>

      <b-modal id="modal-patient" size="xl" title="Pilih Pasien" hide-footer>
        <!-- Tabs -->
        <b-tabs>
          <b-tab title="Daftar Pasien" active>
            <div class="mt-5">
              <Table purpose="modal" :selectedDoctor="selectedDoctor" @choosePatient="chosenPatient" />
            </div>
          </b-tab>
          <b-tab title="Tambah Baru">
            <div class="mt-4" v-if="!isCashier">
              <Form :form="form" :route="'patients'" purpose="modal" @submitPatient="submitedPatient" />
            </div>
            <div v-if="isCashier" class="mt-4">
              <b-form @submit.stop.prevent="customPatientFormOnSubmit">
                <b-form-group id="input-group-name" label="Nama Pasien:" label-for="input-name">
                  <b-form-input id="input-name" v-model="form.name" placeholder="Nama Pasien" required></b-form-input>
                </b-form-group>
                <!-- Button -->
                <b-button type="submit" variant="primary">Simpan</b-button>
                <b-button class="ml-2" variant="default" @click="$bvModal.hide('modal-patient')">
                  Batal
                </b-button>
              </b-form>
            </div>
          </b-tab>
        </b-tabs>
      </b-modal>

    </div>
  </v-app>
</template>

<script>

import Form from '@/component/patients/Form.vue'
import Table from '@/component/patients/TablePatientPayment.vue'

export default {

  props: {
    selectedDoctor: String,
    isCashier: Boolean,
  },

  components: {
    Form,
    Table
  },

  methods: {

    chosenPatient(value) {
      this.$emit('chosenPatient', value)
    },

    submitedPatient(value) {
      this.$emit('submitedPatient', value)
    },

    customPatientFormOnSubmit() {
      this.$emit('customSubmitedPatient', this.form.name)
    }

  },

  data() {
    return {
      form: {
        email: "",
        name: "",
        patient_id: "",
        birt_place: "",
        birt_date: "",
        address: "",
        gender: "",
        phone: "",
        mobile_phone: "",
        parent_name: "",
        blood_type: "",
        id_card_number: "",
        allergy: "",
        password: "",
        password_confirmation: "",
        status: "",
        title: "",
        doctor_in_charge: [],
        responsible_doctor: [],
        photo: [],
        photo_name: s3Endpoint + "/assets/public/default-profile.svg",
        photo_default: "/assets/public/default-profile.svg",
        notes: "",
        isResetImg: false,
        province_id: null,
        capital_id: null,
        modalForm: true,
      }
    }
  }

}
</script>

<style></style>